import React, { useState } from 'react';

import PayUButton from '../components/PayUButton';
import SEO from '../components/SEO';
import Layout from '../components/Layout';

const PaymentLinkPage = () => {
    const [amount, setAmount] = useState('');
    const [productName, setProductName] = useState('');
    return (
        <Layout>
            <SEO
                title="Generate Payment Link"
                description="Admin generate payment link"
            />
            <section className="section">
                <div className="container">
                    <div className="content">
                        <h1>Generate Payment Link</h1>
                        <p>
                            Enter an amount below then click to generate Pay U
                            link
                        </p>
                        <div className="field">
                            <label className="label">
                                Amount (specify amount in USD using the lowest
                                currency unit e.g 50000 is equal to $500.00)
                            </label>
                            <div className="control">
                                <input
                                    style={{
                                        width: '200px',
                                        marginBottom: '20px'
                                    }}
                                    className="input"
                                    type="number"
                                    value={amount}
                                    onChange={e => setAmount(e.target.value)}
                                />
                            </div>
                            <label className="label">
                                Product Name (e.g. Deposit, Final payment, Care
                                Package)
                            </label>
                            <div className="control">
                                <input
                                    style={{
                                        width: '200px',
                                        marginBottom: '20px'
                                    }}
                                    className="input"
                                    type="text"
                                    value={productName}
                                    onChange={e =>
                                        setProductName(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <PayUButton
                            disabled={
                                !amount ||
                                !productName ||
                                productName.length < 2
                            }
                            productName={productName.toString()}
                            amount={amount.toString()}
                            text="Generate Link"
                        />
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default PaymentLinkPage;
